import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";

export function createVoxVivaVuetify(defaultTheme: "dark" | "light") {
  const primary = {
    "primary-c050": "#eceff1",
    "primary-c100": "#cfd8dc",
    "primary-c200": "#b0bec5",
    "primary-c300": "#90a4ae",
    "primary-c400": "#78909c",
    "primary-c500": "#607d8b",
    "primary-c600": "#546e7a",
    "primary-c700": "#455a64",
    primary: "#455a64",
    "primary-c800": "#37474f",
    "primary-c900": "#263238",
  };

  const secondary = {
    "secondary-c050": "#fff8e1",
    "secondary-c100": "#ffecb3",
    "secondary-c200": "#ffe082",
    "secondary-c300": "#ffd54f",
    "secondary-c400": "#ffca28",
    "secondary-c500": "#ffc107",
    secondary: "#ffc107",
    "secondary-c600": "#ffb300",
    "secondary-c700": "#ffa000",
    "secondary-c800": "#ff8f00",
    "secondary-c900": "#ff6f00",
  };

  const error = {
    "error-c050": "#ffebee",
    "error-c100": "#ffcdd2",
    "error-c200": "#ef9a9a",
    "error-c300": "#e57373",
    "error-c400": "#ef5350",
    "error-c500": "#f44336",
    error: "#f44336",
    "error-c600": "#e53935",
    "error-c700": "#d32f2f",
    "error-c800": "#c62828",
  };

  return createVuetify({
    // See https://github.com/vuetifyjs/vuetify/issues/17875
    // and https://github.com/vuetifyjs/vuetify/issues/19015#issuecomment-2119966112
    ssr: true,

    theme: {
      defaultTheme,
      variations: false,
      themes: {
        light: {
          dark: false,
          colors: {
            ...primary,
            ...secondary,
            ...error,
            surface: "#ffffff",
            background: primary["primary-c050"],
            info: primary.primary,
          },
        },
        dark: {
          dark: true,
          colors: {
            ...primary,
            ...secondary,
            ...error,
            surface: "#1e1e1e",
            info: primary.primary,
          },
        },
      },
    },

    // This tells Vuetify to use the mdiSvg icon set internally (e.g., when rendering a checkbox).
    // It does not affect which SVG icons are used in our own components.
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: { mdi },
    },

    components: {},
  });
}

import { defineStore } from "pinia";
import { type MaybeRef, ref } from "vue";
import { parseVidsString, VidRange } from "@rsc/scripture-util";
import {
  type CommentaryType,
  getCommentaryTypeOrFallback,
} from "~/static/taxonomy/commentaryTypes";
import {
  type DetailLevel,
  getDetailLevelFromWeightWithFallback,
} from "~/static/taxonomy/detailLevels";

/**
 * A pinia store for the commentary filter state.
 *
 * Only store things here which are also represented in the URL.
 *
 * All values should be synced with the route.
 */
export const useCommentaryFiltersStore = defineStore(
  "commentaryFilters",
  () => {
    /**
     * The VID range string.
     */
    const vids = ref<string>();

    /**
     * The detail level weight.
     */
    const detailLevelWeight = ref<number>();

    /**
     * The commentary type name.
     */
    const commentaryTypeShortName = ref<string>();

    function $reset() {
      vids.value = undefined;
      detailLevelWeight.value = undefined;
      commentaryTypeShortName.value = undefined;
    }

    return {
      vids,
      detailLevelWeight,
      commentaryTypeShortName,

      getVidRange(defaultVids: MaybeRef<string>): VidRange {
        return (
          parseVidsString(vids.value ?? unref(defaultVids), null)[0] ??
          new VidRange(181, 181)
        ); // The fallback is expected to never happen here.
      },

      getDetailLevel(defaultDetailLevelWeight: MaybeRef<number>): DetailLevel {
        return getDetailLevelFromWeightWithFallback(
          detailLevelWeight.value ?? unref(defaultDetailLevelWeight),
        );
      },

      getCommentaryType(
        defaultCommentaryTypeShortName: MaybeRef<string>,
      ): CommentaryType {
        return getCommentaryTypeOrFallback(
          commentaryTypeShortName.value ??
            unref(defaultCommentaryTypeShortName),
        );
      },

      getFilters(defaults: CommentaryFilters): CommentaryFilters {
        return {
          vids: vids.value ?? defaults.vids,
          detailLevelWeight:
            detailLevelWeight.value ?? defaults.detailLevelWeight,
          commentaryTypeShortName:
            commentaryTypeShortName.value ?? defaults.commentaryTypeShortName,
        };
      },

      setFilters(filters: CommentaryFilters): void {
        vids.value = filters.vids;
        detailLevelWeight.value = filters.detailLevelWeight;
        commentaryTypeShortName.value = filters.commentaryTypeShortName;
      },

      $reset,
    };
  },
  {
    // Persist the commentary filters, but not the commentary search results.
    // Use cookies for the commentary filters because it affects SSR markup, e.g.,
    // - breadcrumbs on the comment page
    // - back buttons on the comment page
    // - "Commentary" button in the bottom navigation.
    persist: {
      // IMPORTANT: When using cookie persistence, we should explicitly allow the cookie through CloudFront.
      // See CloudFront → Policies → Cache → VV-CachingOptimized-WithCookies-WithQuery → Edit cache policy → Cache key settings
      storage: persistedState.cookiesWithOptions({
        sameSite: "strict",
      }),
    },
  },
);

export type CommentaryFiltersStore = ReturnType<
  typeof useCommentaryFiltersStore
>;

export interface CommentaryFilters {
  vids: string;
  detailLevelWeight: number;
  commentaryTypeShortName: string;
}

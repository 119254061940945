<template lang="pug">
VBtn(
  variant="outlined"
  :color="$vuetify.theme.name === 'dark' ? 'primary-c100' : 'primary-c700'"
  :icon="mdiTranslateVariant"
  title="Language settings"
  aria-label="Language settings"
  @click="openSheet"
)
</template>

<script setup lang="ts">
import { mdiTranslateVariant } from "@mdi/js";
import { useLanguageSheetStore } from "~/stores/useLanguageSheetStore";

const languageSheetStore = useLanguageSheetStore();
const openSheet = () => languageSheetStore.open();
</script>

<template lang="pug">
div.surface.mb-8
  VContainer
    HH.text-h1.mt-8.mb-6
      | {{ i18n.t("Error.404NotFound") }}
TheError(
  title=""
  :message="i18n.t('Error.NotFound')"
  :status-code="404"
)
  blockquote
    p {{ i18n.t("Error.SoS3:1c") }}
    cite {{ i18n.t("Error.SoS3:1cRef") }}
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import HH from "~/headings/components/HH.vue";

const i18n = useI18n();
</script>

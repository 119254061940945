<template lang="pug">
div.hero
  div.hero__bg
  VContainer.hero__container
    div.hero__logo
      VvLogo(
        logo-id="vv-logo-home-banner"
        :auto="true"
      )
    VvTitle.hero__title(:text="true")
    h1.d-sr-only Vox Viva
    i18n-t.hero__punchline(
      keypath="TheHomeBanner.punchline"
      tag="div"
      scope="global"
    )
      br
    div.hero__cta
      VBtn(
        :to="localePath(bibleLocation)"
        :size="$vuetify.display.mdAndUp ? 'x-large' : 'large'"
        color="primary-c700"
        elevation="5"
      )
        VIcon.mr-2 {{ bibleOutline }}
        | {{ i18n.t("Global.read") }}
      VBtn.text-black(
        :to="localePath(commentaryLocation)"
        :size="$vuetify.display.mdAndUp ? 'x-large' : 'large'"
        color="secondary-c500"
        elevation="5"
      )
        VIcon.mr-2 {{ commentaryOutline }}
        | {{ i18n.t("Global.study") }}
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { bibleOutline, commentaryOutline } from "~/icons";
import { useBibleStore } from "~/stores/useBibleStore";
import {
  getBibleLocationFromState,
  getCommentaryLocationFromFilters,
} from "~/routing/locations";
import { useCommentaryFiltersStore } from "~/stores/useCommentaryFiltersStore";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";

const runtimeConfig = useRuntimeConfig();

const i18n = useI18n();
const localePath = useLocalePath();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);

const bibleStore = useBibleStore();
const bibleLocation = computed(() =>
  getBibleLocationFromState(
    bibleStore.getRouteState(
      runtimeConfig.public.bible.defaultVids,
      localeConfig.value.defaultTid,
    ),
  ),
);

const commentaryFiltersStore = useCommentaryFiltersStore();
const defaultCommentaryFilters: CommentaryFilters = {
  vids: runtimeConfig.public.commentary.defaultVids,
  detailLevelWeight: runtimeConfig.public.commentary.defaultDetailLevelWeight,
  commentaryTypeShortName: runtimeConfig.public.commentary.defaultType,
};

const commentaryLocation = computed(() =>
  getCommentaryLocationFromFilters(
    commentaryFiltersStore.getFilters(defaultCommentaryFilters),
  ),
);
</script>

<style lang="scss">
@use "../assets/styles/abstract/functions" as f;
@use "../assets/styles/abstract/fonts" as fo;

.hero {
  background: rgb(var(--v-theme-secondary-c100));
  position: relative;

  .v-theme--dark & {
    background: rgb(var(--v-theme-secondary-c600));
  }
}

.hero__bg {
  opacity: 0.3;
  background-image: url("../assets/images/h500.jpg");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (min-width: 960px) {
    // "md-and-up"
    background-image: url("../assets/images/h800.jpg");
  }
  @media (min-width: 1280px) {
    // "lg-and-up"
    background-image: url("../assets/images/wide_h1000.jpg");
  }
}

.hero__container {
  text-align: center;
  position: relative;
}

.hero__logo {
  height: f.rem(90px);
  margin: f.rem(24px) auto;

  @media (min-width: 600px) {
    // "sm-and-up"
    height: f.rem(140px);
    margin: f.rem(32px) auto;
  }

  @media (min-width: 960px) {
    // "md-and-up"
    height: f.rem(200px);
    margin: f.rem(40px) auto;
  }
}

.hero__title {
  font-size: f.rem(25px);

  @media (min-width: 600px) {
    // "sm-and-up"
    height: f.rem(35px);
  }

  @media (min-width: 960px) {
    // "md-and-up"
    height: f.rem(50px);
  }
}

.hero__punchline {
  font-family: fo.$heading;
  font-style: italic;
  letter-spacing: em(0.5, 16);
  font-size: f.rem(20);
  font-weight: 500;
  line-height: 1.33;
  color: rgb(var(--v-theme-primary-c900));
  margin: f.rem(36px) auto;

  @media (min-width: 600px) {
    // "sm-and-up"
    font-size: f.rem(25);
    margin: f.rem(48px) auto;
  }

  @media (min-width: 960px) {
    // "md-and-up"
    font-size: f.rem(30);
    margin: f.rem(60px) auto;
  }
}

.hero__cta {
  margin: f.rem(24px) auto;
  display: flex;
  flex-wrap: wrap;
  gap: f.rem(24);
  justify-content: center;

  @media (min-width: 600px) {
    // "sm-and-up"
    margin: f.rem(32px) auto;
    gap: f.rem(32);
  }

  @media (min-width: 960px) {
    // "md-and-up"
    margin: f.rem(40px) auto;
    gap: f.rem(40);
  }
}
</style>

<template lang="pug">
div
  // TODO: Figure out how to do `:attach="$el"` in Vue 3.
  VMenu.index-study-menu
    template(#activator="activatorProps")
      VBtn.text-black.ml-2(
        v-bind="activatorProps['props']"
        color="secondary-c500"
        :disabled="count === 0"
        :variant="countLocale > 0 ? 'flat' : 'outlined'"
      ) {{ i18n.t("Global.study") }}
    VList
      VListItem(
        v-for="{ commentaryType, countData } in commentaryTypes"
        :key="commentaryType.shortName"
        :to="localePath(getCommentaryLocationFromRange(commentaryType.shortName, range))"
      )
        template(#prepend)
          CommentaryTypeIcon(
            :short-name="commentaryType.shortName"
            variant="outline"
          )
        VListItemTitle {{ commentaryType.name[locale] }}
        template(#append)
          VChip.ml-4(
            size="small"
            :color="countData.countLocal > 0 ? 'secondary-c500' : $vuetify.theme.name === 'dark' ? 'primary-c100' : 'primary-c700'"
            :variant="countData.countLocal > 0 ? 'flat' : 'text'"
          )
            span(v-html="countData.countHtml")
</template>

<script setup lang="ts">
import type { TranslationAgnosticVidRange } from "@rsc/scripture-util";
import { useI18n } from "vue-i18n";
import {
  type CommentaryIndexCommentaryTypeCount,
  convertCountstoMap,
} from "~/units/commentary/commentaryIndex";
import { getCommentaryLocationFromRange } from "~/routing/locations";
import { getCommentaryTypeWithCountData } from "./CommentaryType.util";

const i18n = useI18n();
const locale = i18n.locale;
const localePath = useLocalePath();

interface Props {
  range: TranslationAgnosticVidRange;
  commentaryTypesCounts?: {
    [language: string]: CommentaryIndexCommentaryTypeCount[];
  };
  count: number;
  countLocale: number;
  otherLanguages: string[];
}

const props = defineProps<Props>();

const commentaryTypes = computed(() => {
  const countsMap = convertCountstoMap(props.commentaryTypesCounts ?? {});
  return getCommentaryTypeWithCountData({
    locale: unref(locale),
    counts: countsMap,
    otherLanguages: props.otherLanguages,
  });
});
</script>

import { createRouteMiddleware } from "~/composables/useStateRouteBinding";
import {
  expectSimpleNamedLocation,
  getCommentaryLocationFromFilters,
} from "~/routing/locations";
import { getDesiredCommentaryStateFromLocation } from "~/routing/commentaryFiltersAndRoute";
import {
  type CommentaryFilters,
  useCommentaryFiltersStore,
} from "~/stores/useCommentaryFiltersStore";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useCommentaryFiltersStore();
  const runtimeConfig = useRuntimeConfig();
  const defaults: CommentaryFilters = {
    vids: runtimeConfig.public.commentary.defaultVids,
    detailLevelWeight: runtimeConfig.public.commentary.defaultDetailLevelWeight,
    commentaryTypeShortName: runtimeConfig.public.commentary.defaultType,
  };
  const nuxt = useNuxtApp();

  const ware = createRouteMiddleware(
    () => store.getFilters(defaults),
    (value) => store.setFilters(value),
    (state) =>
      expectSimpleNamedLocation(
        nuxt.$localeRoute(getCommentaryLocationFromFilters(state)),
      ),
    getDesiredCommentaryStateFromLocation,
  );

  return ware(to, from);
});

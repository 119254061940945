<template lang="pug">
VSelect.select-language(
  :id="id"
  v-model="model"
  :items="i18n.locales.value"
  :label="i18n.t('Settings.Language')"
  :color="$vuetify.theme.name === 'dark' ? 'secondary' : 'secondary-c900'"
  variant="outlined"
  :hide-details="true"
  item-title="name"
  item-value="code"
)
</template>

<script setup lang="ts">
/**
 * Language switcher.
 * See https://v8.i18n.nuxtjs.org/guide/lang-switcher
 */

const i18n = useI18n();

const model = computed<string>({
  get() {
    return i18n.locale.value;
  },
  set(val) {
    i18n.setLocale(val);
  },
});

const id = useId();
</script>

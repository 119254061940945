import type { RouterConfig } from "@nuxt/schema";
import { bibleRouteNameRegex } from "~/routing/regex";

export default <RouterConfig>{
  routes: (originalRoutes) => {
    return [
      {
        name: "root",
        path: "/",
        component: () =>
          import("~/extra-pages/rootPage.vue").then((r) => r.default || r),
      },
      ...originalRoutes,
    ];
  },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // This is triggered by the browser's back or forward button.
      return savedPosition;
    } else if (
      bibleRouteNameRegex.test(String(to.name ?? "")) &&
      bibleRouteNameRegex.test(String(from.name ?? ""))
    ) {
      // Don't scroll to the top when navigating within the bible page.
      return false;
    } else {
      // This is a new navigation. Scroll to the top.
      return { left: 0, top: 0 };
    }
  },
};

import type { Locale } from "vue-i18n";

/**
 * Merge the current locale with other accepted locales.
 *
 * @param current
 *   The current language.
 * @param accepted
 *   Other accepted languages.
 *
 * @returns
 *   An array of unique languages.
 *   The current language will be first in the array.
 */
export function getMergedLanguages(
  current: Locale,
  accepted: Locale[],
): Locale[] {
  return [...new Set([current, ...(accepted ?? [])])];
}

/**
 * Get a list of accepted languages, excluding the current language.
 *
 * @param current
 *   The current locale.
 * @param accepted
 *   Other accepted languages.
 *
 * @returns
 *   An array of unique languages.
 *   The current language will not be in the array.
 */
export function getFilteredLanguages(
  current: Locale,
  accepted: Locale[],
): Locale[] {
  return (accepted ?? []).filter(
    (acceptedLocale) => acceptedLocale !== current,
  );
}

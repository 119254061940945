import {
  knownCommentaryTypes,
  type CommentaryType,
} from "~/static/taxonomy/commentaryTypes";

/**
 * The arguments of the `getCountData` function.
 */
interface GetCountDataConfig {
  /**
   * The short name of the commentary type.
   */
  shortName: string;

  /**
   * The number of comments for each language and commentary type, first keyed by locale and then by commentary type.
   */
  counts: Map<string, Map<string, number>>;

  /**
   * The user's preferred language.
   */
  locale: string;

  /**
   * The list of other languages that the user can read.
   */
  otherLanguages: string[];
}

/**
 * The data returned by the `getCountData` function.
 */
export interface CountData {
  /**
   * TODO: What is this?
   */
  countTotal: number;

  /**
   * The total number of comments in the preferred language.
   */
  countLocal: number;

  /**
   * The maximum number of comments in any other language that the user can read.
   */
  maxCountOther: number | null;

  /**
   * The HTML representation of the counts.
   */
  countHtml: string;
}

export const getCountData = ({
  shortName,
  counts,
  locale,
  otherLanguages,
}: GetCountDataConfig): CountData => {
  const countLocal = counts.get(locale)?.get(shortName) ?? 0;
  const maxCountOther = otherLanguages.reduce(
    (acc: null | number, currentOtherLocale: string) => {
      const count = counts.get(currentOtherLocale)?.get(shortName) ?? 0;
      return Math.max(count, acc ?? 0);
    },
    null,
  );
  return {
    countTotal: Math.max(countLocal, maxCountOther ?? 0),
    countLocal,
    maxCountOther,
    countHtml: createCountHtml({ countLocal, maxCountOther }),
  };
};

/**
 * Create a human-readable representation of the counts.
 *
 * @param countLocal
 *   The total number of comments in the preferred language.
 * @param maxCountOther
 *   The maximum number of comments in any other language that the user can read.
 */
export const createCountHtml = ({
  countLocal,
  maxCountOther,
}: {
  countLocal: number;
  maxCountOther: number | null;
}): string => {
  return `<strong>${countLocal}</strong>${maxCountOther === null ? "" : ` (${maxCountOther})`}`;
};

/**
 * The arguments of the `getCommentaryTypeWithCountData` function.
 */
interface GetCommentaryTypeWithCountDataConfig {
  /**
   * The number of comments for each language and commentary type, first keyed by locale and then by commentary type.
   */
  counts: Map<string, Map<string, number>>;

  /**
   * The user's preferred language.
   */
  locale: string;

  /**
   * The list of other languages that the user can read.
   */
  otherLanguages: string[];
}

/**
 * A commentary type and its associated counts.
 */
interface CommentaryTypeWithCountData {
  commentaryType: CommentaryType;
  countData: CountData;
}

/**
 * Get the commentary types with their associated counts.
 */
export function getCommentaryTypeWithCountData({
  counts,
  locale,
  otherLanguages,
}: GetCommentaryTypeWithCountDataConfig): CommentaryTypeWithCountData[] {
  return knownCommentaryTypes.reduce(
    (
      acc: CommentaryTypeWithCountData[],
      commentaryType: CommentaryType,
    ): CommentaryTypeWithCountData[] => {
      const shortName = commentaryType.shortName;
      const countData = getCountData({
        shortName,
        locale,
        counts,
        otherLanguages: otherLanguages,
      });
      if (countData.countTotal > 0) {
        acc.push({
          commentaryType,
          countData,
        });
      }
      return acc;
    },
    [],
  );
}

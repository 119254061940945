import type {
  CountInRangesResult,
  SolrClientError,
  SolrClientInterface,
} from "@rsc/vv-solr-client";
import type { TranslationAgnosticVidRange } from "@rsc/scripture-util";
import type { ResultAsync } from "neverthrow";
import { isDefined } from "../typescriptHelpers";
import {
  type CommentaryType,
  knownCommentaryTypes,
} from "~/static/taxonomy/commentaryTypes";

export interface CommentaryIndexCommentaryTypeCount {
  commentaryType: CommentaryType;
  count: number;
}

export interface CommentaryIndexRangeCount<
  T extends TranslationAgnosticVidRange,
> {
  range: T;
  count: number;
  languagesCounts: {
    [language: string]: number;
  };
  commentaryTypesCounts: {
    [language: string]: CommentaryIndexCommentaryTypeCount[];
  };
}

export type CommentaryIndexByRanges<T extends TranslationAgnosticVidRange> = {
  count: number;
  ranges: CommentaryIndexRangeCount<T>[];
};

/**
 * Get the commentary index for the given ranges.
 *
 * @param ranges
 *   The ranges for which we want to get the commentary index. This may be books, chapters, or arbitrary verses.
 * @param solr
 *   A solr client.
 * @param languages
 *   An array of languages whose comments to include.
 */
export function getCommentaryIndexByRanges<
  T extends TranslationAgnosticVidRange,
>(
  ranges: T[],
  solr: SolrClientInterface,
  languages: string[],
): ResultAsync<CommentaryIndexByRanges<T>, SolrClientError> {
  return solr.countInRanges(ranges, languages).map((data) => {
    return {
      count: data.count,
      ranges: ranges.map(getMapRangeFunction(data)),
    };
  });
}

function getMapRangeFunction<T extends TranslationAgnosticVidRange>(
  solrResults: CountInRangesResult,
): (range: T) => CommentaryIndexRangeCount<T> {
  return function (range: T): CommentaryIndexRangeCount<T> {
    const bucket = (solrResults.vidRanges?.buckets || []).find(
      (b) => b.val === `[${range.fromVid},${range.toVid}]`,
    );
    const languagesBuckets = bucket?.languages?.buckets || [];

    return {
      range,
      count: bucket?.count || 0,
      languagesCounts: languagesBuckets.reduce(
        (acc: { [language: string]: number }, { val, count }) => {
          acc[val] = count;
          return acc;
        },
        {},
      ),
      commentaryTypesCounts: languagesBuckets.reduce(
        (
          acc: { [language: string]: CommentaryIndexCommentaryTypeCount[] },
          current,
        ) => {
          const language = current.val;
          const commentaryCount = (current.commentaryType?.buckets || [])
            .map(mapCommentaryType)
            .filter(isDefined)
            .sort(
              (
                a: CommentaryIndexCommentaryTypeCount,
                b: CommentaryIndexCommentaryTypeCount,
              ) => a.commentaryType.weight - b.commentaryType.weight,
            );
          if (commentaryCount.length) {
            acc[language] = commentaryCount;
          }
          return acc;
        },
        {},
      ),
    };
  };
}

function mapCommentaryType(solrBucket: {
  count: number;
  val: string;
}): CommentaryIndexCommentaryTypeCount | undefined {
  const commentaryType = knownCommentaryTypes.find(
    (t) => t.shortName === solrBucket.val,
  );
  if (!commentaryType) {
    return;
  }

  return { commentaryType, count: solrBucket.count };
}

export function convertCountstoMap(commentaryTypesCounts: {
  [language: string]: CommentaryIndexCommentaryTypeCount[];
}): Map<string, Map<string, number>> {
  return new Map(
    Object.entries(commentaryTypesCounts).map(([lang, typeCounts]) => {
      const types: [string, number][] = (typeCounts ?? []).map(
        ({ count, commentaryType }) => {
          return [commentaryType.shortName, count];
        },
      );
      return [lang, new Map(types)];
    }),
  );
}

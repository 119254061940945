import type { RouteLocationNormalized } from "vue-router";
import { parseIntWithFallback } from "~/units/strings";
import {
  getStringParam,
  getStringQueryParameter,
  type UpdateQueryAndParamsFunction,
} from "~/routing/routes";
import {
  type LocationQueryAndParams,
  useStateRouteBinding,
} from "~/composables/useStateRouteBinding";
import type {
  CommentaryFilters,
  CommentaryFiltersStore,
} from "~/stores/useCommentaryFiltersStore";
import { getCommentaryLocationFromFilters } from "~/routing/locations";
import { commentaryRouteNameRegex } from "~/routing/regex";
import { never } from "~/never";
import { commaSeparatedStringToArray } from "~/stores/util";

/**
 * Create a two-way binding between commentary state and the route.
 */
export function bindCommentaryFiltersAndRoute(
  commentaryFiltersStore: CommentaryFiltersStore,
  getRoute: () => Pick<RouteLocationNormalized, "name" | "query" | "params">,
  updateQueryAndParams: UpdateQueryAndParamsFunction,
): void {
  useStateRouteBinding<CommentaryFilters>(
    () => {
      const { vids, detailLevelWeight, commentaryTypeShortName } =
        commentaryFiltersStore;

      if (
        vids === undefined ||
        detailLevelWeight === undefined ||
        commentaryTypeShortName === undefined
      ) {
        // The store is not ready yet.
        return undefined;
      }

      return {
        vids,
        detailLevelWeight,
        commentaryTypeShortName,
      };
    },
    (value) => {
      commentaryFiltersStore.$patch(value);
    },
    hasStateChanged,
    getRoute,
    updateQueryAndParams,
    commentaryRouteNameRegex,
    getCommentaryLocationFromFilters,
    getDesiredCommentaryStateFromLocation,
  );
}

function hasStateChanged(
  from: CommentaryFilters,
  to: CommentaryFilters,
): boolean {
  return (
    from.vids !== to.vids ||
    from.detailLevelWeight !== to.detailLevelWeight ||
    from.commentaryTypeShortName !== to.commentaryTypeShortName
  );
}

/**
 * Given the current route's query and parameters, figure out what the Commentary state should be.
 * Fall back to values from the previous state where the route does not specify a value.
 *
 * @param location The current route's query and parameters.
 * @param previousFilters The previous Commentary filters state.
 *
 * @returns The desired Commentary state.
 */
export function getDesiredCommentaryStateFromLocation(
  location: LocationQueryAndParams,
  previousFilters: CommentaryFilters | undefined,
): CommentaryFilters {
  return {
    vids:
      (getStringParam(location, "vids") || previousFilters?.vids) ??
      never("Unknown verse IDs in `getDesiredCommentaryStateFromLocation`."),
    commentaryTypeShortName:
      (getStringParam(location, "type") ||
        previousFilters?.commentaryTypeShortName) ??
      never(
        "Unknown commentary type in `getDesiredCommentaryStateFromLocation`.",
      ),
    detailLevelWeight: parseIntWithFallback(
      getStringQueryParameter(location, "detail") ?? "",
      previousFilters?.detailLevelWeight ??
        never(
          "Unknown detail level in `getDesiredCommentaryStateFromLocation`.",
        ),
    ),
  };
}

import { defineStore } from "pinia";
import { ref } from "vue";

interface LanguagePayload {
  /**
   * The optional comments counts.
   */
  counts?: Map<string, number> | null;
}

/**
 * A pinia store for manage the global language sheet
 */
export const useLanguageSheetStore = defineStore("language-sheet", () => {
  const getInit = () => ({
    visible: false,
    counts: null,
  });
  const init = getInit();

  const visible = ref<boolean>(init.visible);
  const counts = ref<Map<string, number> | null>(init.counts);

  function $reset() {
    const init = getInit();
    visible.value = init.visible;
    counts.value = init.counts;
  }

  /**
   * Display the language sheet.
   */
  function open(payload?: LanguagePayload) {
    counts.value = payload?.counts ?? null;
    visible.value = true;
  }

  function close() {
    visible.value = false;
    counts.value = null;
  }

  return { visible, counts, open, close, $reset };
});

export type LanguageSheetStore = ReturnType<typeof useLanguageSheetStore>;

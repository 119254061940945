<template lang="pug">
VThemeProvider(theme="dark")
  // The `z-index` style is a workaround: See https://github.com/vuetifyjs/vuetify/issues/19871
  VBottomNavigation.no-print.the-bottom-nav(
    tag="nav"
    aria-label="Main"
    model-value="activeButton"
    style="z-index: 1006"
  )
    VBtn(
      :to="localePath(getHomeLocation())"
      :exact="true"
    )
      VIcon(size="large") {{ activeButton === "home" ? mdiHome : mdiHomeOutline }}
      span {{ i18n.t("TheBottomNav.Home") }}
    VBtn(:to="localePath(bibleLocation)")
      VIcon(size="large") {{ activeButton === "bible" ? bibleFilled : bibleOutline }}
      span {{ i18n.t("TheBottomNav.Bible") }}
    VBtn(:to="localePath(commentaryLocation)")
      VIcon(size="large") {{ activeButton === "commentary" ? commentaryFilled : commentaryOutline }}
      span {{ i18n.t("TheBottomNav.Commentary") }}
    VBtn(
      :active="menuOpen"
      @click="emit('update:menuOpen', !menuOpen)"
    )
      VIcon(size="large") {{ mdiMenu }}
      span {{ i18n.t("TheBottomNav.Menu") }}
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { mdiHome, mdiHomeOutline, mdiMenu } from "@mdi/js";
import { useI18n } from "vue-i18n";
import {
  bibleFilled,
  bibleOutline,
  commentaryFilled,
  commentaryOutline,
} from "~/icons";
import { useBibleStore } from "~/stores/useBibleStore";
import { useCommentaryFiltersStore } from "~/stores/useCommentaryFiltersStore";
import {
  getBibleLocationFromState,
  getCommentaryLocationFromFilters,
  getHomeLocation,
} from "~/routing/locations";
import {
  bibleRouteNameRegex,
  commentaryRouteNameRegex,
  commentRouteNameRegex,
  homeRouteNameRegex,
} from "~/routing/regex";
import { usePwaStore } from "~/stores/usePwaStore";
import { usePreferencesStore } from "~/stores/usePreferencesStore";
import { validCommentaryTypeShortNames } from "~/static/taxonomy/commentaryTypes";
import { getStringParam } from "~/routing/routes";
import { localesByCode } from "~/i18n/locales";
import { never } from "~/never";

const i18n = useI18n();
const localePath = useLocalePath();
const route = useRoute();
const preferencesStore = usePreferencesStore();
const pwaStore = usePwaStore();

interface Props {
  menuOpen?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:menuOpen", value: boolean): void;
}

const emit = defineEmits<Emits>();

/**
 * Whether to show a badge on the more button.
 * TODO: Remove this?
 */
const showMoreBadge = computed<boolean>(() => {
  const updateBadge = pwaStore.updateAvailable;
  const installBadge =
    !preferencesStore.doNotInstall && !!pwaStore.deferredInstallPrompt;
  return updateBadge || installBadge;
});

/**
 * The name of the active button.
 */
const activeButton = computed<"home" | "bible" | "commentary" | "">(() => {
  const name = String(route.name ?? "");

  if (commentaryRouteNameRegex.test(name) || commentRouteNameRegex.test(name)) {
    return "commentary";
  }

  if (bibleRouteNameRegex.test(name)) {
    return "bible";
  }

  if (homeRouteNameRegex.test(name)) {
    return "home";
  }

  return "";
});

const bibleStore = useBibleStore();
const runtimeConfig = useRuntimeConfig();
const localeConfig = computed(
  () => localesByCode.get(i18n.locale.value) ?? never("Locale not found."),
);

const bibleLocation = computed(() =>
  getBibleLocationFromState(
    bibleStore.getRouteState(
      runtimeConfig.public.bible.defaultVids,
      localeConfig.value.defaultTid,
    ),
  ),
);

const commentaryFiltersStore = useCommentaryFiltersStore();
const defaultCommentaryFilters: CommentaryFilters = {
  vids: runtimeConfig.public.commentary.defaultVids,
  detailLevelWeight: runtimeConfig.public.commentary.defaultDetailLevelWeight,
  commentaryTypeShortName: runtimeConfig.public.commentary.defaultType,
};

const commentaryLocation = computed(() => {
  const to = getCommentaryLocationFromFilters(
    commentaryFiltersStore.getFilters(defaultCommentaryFilters),
  );

  // Validate the location, so that we do not go to a 404.
  if (
    !validCommentaryTypeShortNames.includes(getStringParam(to, "type") || "")
  ) {
    // Invalid commentary type. Change to the default.
    to.params.type = runtimeConfig.public.commentary.defaultType;
  }

  return to;
});
</script>

<style lang="scss" scoped>
// The style needs to be very specific to avoid wrong display in production
.v-bottom-navigation.the-bottom-nav {
  .v-btn {
    // Make buttons in bottom nav equally wide.
    flex: 1 1 0;

    // There seems to be a CSS specificity instability in Vuetify, causing the buttons to sometimes have a height of
    // `inherit`, and sometimes `36px`, depending on CSS order. Make sure the buttons are as high as
    // the nav bar. See https://github.com/vuetifyjs/vuetify/issues/5271 .
    height: inherit;

    // Let use background-color of the bottom nav
    background-color: transparent;
  }
}

.the-bottom-nav {
  width: 100%; // Prevend weird width during loading page from ssr
  backdrop-filter: blur(10px);
  background-color: rgba(var(--v-theme-primary-c900), 0.9);
}
</style>

<template lang="pug">
span
  VMenu.index-study-menu(v-if="indexComputed.count")
    template(#activator="activatorProps")
      slot(
        name="activator"
        v-bind="{ activatorProps, countLocale: indexComputed.countLocale }"
      )
        VBtn.text-black(
          v-bind="activatorProps['props']"
          color="secondary-c500"
          :variant="indexComputed.countLocale > 0 ? 'flat' : 'outlined'"
        )
          VIcon {{ commentaryOutline }}
          | {{ i18n.t("Global.study") }}
    VList
      VListItem(
        v-for="{ commentaryType, countData } in indexComputed.commentaryTypes"
        :key="commentaryType.shortName"
        :to="localePath(getCommentaryLocationFromRange(commentaryType.shortName, indexComputed.range))"
      )
        template(#prepend)
          CommentaryTypeIcon(
            :short-name="commentaryType.shortName"
            variant="outline"
          )
        VListItemTitle {{ commentaryType.name[locale] }}
        template(#append)
          VChip.ml-4(
            size="small"
            :color="countData.countLocal > 0 ? 'secondary-c500' : $vuetify.theme.name === 'dark' ? 'primary-c100' : 'primary-c700'"
            :variant="countData.countLocal > 0 ? 'flat' : 'text'"
          )
            span(v-html="countData.countHtml")
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { TranslationAgnosticVidRange } from "@rsc/scripture-util";
import { getCommentaryLocationFromRange } from "~/routing/locations";
import { usePreferencesStore } from "~/stores/usePreferencesStore";
import { commentaryOutline } from "~/icons";
import { injectRequired } from "~/injectRequired";
import { SolrClientKey } from "~/injectionKeys";
import {
  convertCountstoMap,
  getCommentaryIndexByRanges,
} from "~/units/commentary/commentaryIndex";
import { getCommentaryTypeWithCountData } from "./CommentaryType.util";

const solrClient = injectRequired(SolrClientKey);

const i18n = useI18n();
const localePath = useLocalePath();
const preferencesStore = usePreferencesStore();

const locale = i18n.locale;

interface Props {
  range: TranslationAgnosticVidRange;
}

const props = defineProps<Props>();

const indexRefreshable = useRefreshableGetter(() =>
  getCommentaryIndexByRanges(
    [props.range],
    solrClient,
    preferencesStore.getLanguages(locale.value),
  ).map((data) => data.ranges[0]),
);

const index = useComputedResultAsync(indexRefreshable.getter);

const indexComputed = computed(() => {
  const countsMap = convertCountstoMap(
    index.value?.commentaryTypesCounts ?? {},
  );
  const commentaryTypes = getCommentaryTypeWithCountData({
    locale: locale.value,
    counts: countsMap,
    otherLanguages: preferencesStore.getOtherLanguages(locale.value),
  });
  const countLocale = index.value?.languagesCounts[locale.value] ?? 0;
  const count = index.value?.count ?? 0;
  const range = index.value?.range ?? props.range;

  return { commentaryTypes, count, countLocale, range };
});
</script>

<template lang="pug">
div.index-list-chapters
  ErrorAlert(
    v-if="error"
    :reporting="{ message: error.message, extra: error }"
    message="Failed to load the scripture index."
    @retry="query.refetch"
  )

  VSkeletonLoader(
    v-else-if="query.isPending.value"
    type="article, actions"
    max-height="180px"
    width="200px"
  )

  VRow.index-list-chapters__row(
    v-else
    v-drag-scroll
    :class="{ 'index-list-chapters__row--side-scroll': sideScroll }"
  )
    VCol(
      v-for="chapter in chapters"
      :key="chapter.range.number"
    )
      IndexCardChapter(
        :index="chapter"
        :other-languages="otherLanguages"
      )
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useQuery } from "@tanstack/vue-query";
import type { StandaloneChapterData } from "@rsc/scripture-model";
import {
  type CommentaryIndexRangeCount,
  getCommentaryIndexByRanges,
} from "~/units/commentary/commentaryIndex";
import { injectRequired } from "~/injectRequired";
import { LoggerKey, ScriptureClientKey, SolrClientKey } from "~/injectionKeys";
import {
  logSsrErrorToSentry,
  makeResultAsyncSerializable,
} from "~/errors/util";
import { usePreferencesStore } from "~/stores/usePreferencesStore";

const solrClient = injectRequired(SolrClientKey);
const scriptureClient = injectRequired(ScriptureClientKey);
const i18n = useI18n();
const locale = i18n.locale;
const logger = injectRequired(LoggerKey);

const preferencesStore = usePreferencesStore();
const languages = computed<string[]>(() =>
  preferencesStore.getLanguages(unref(locale)),
);
const otherLanguages = computed<string[]>(() =>
  preferencesStore.getOtherLanguages(unref(locale)),
);

interface Props {
  /**
   * The ID of the user's preferred local translation.
   */
  localTid: string;

  /**
   * The ID of the book whose commentary index to show.
   */
  bid: string;

  /**
   * If true, show everything on one line, and allow horizontal scrolling by swiping.
   */
  sideScroll?: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const query = useQuery({
  queryKey: [
    "IndexListChapters",
    computed(() => props.localTid),
    computed(() => props.bid),
    languages,
  ],
  queryFn: () =>
    makeResultAsyncSerializable(
      () =>
        scriptureClient
          .getChapters(props.localTid, props.bid)
          .andThen((ranges) =>
            getCommentaryIndexByRanges(ranges, solrClient, languages.value),
          ),
      (e, s) => logSsrErrorToSentry(logger, e, s),
    ),
});

const error = computed(
  () => query.error.value ?? query.data.value?.error ?? null,
);

const chapters = computed<CommentaryIndexRangeCount<StandaloneChapterData>[]>(
  () => query.data.value?.value?.ranges ?? [],
);

onServerPrefetch(async () => {
  await query.suspense();
});
</script>

<style lang="scss" scoped>
.index-list-chapters__row--side-scroll {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: grab;

  &.active {
    cursor: grabbing;
  }
}
</style>

import { DEFAULT_LOCALE_CODE } from "./constant";
import en from "./messages/en.json";
import fr from "./messages/fr.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: DEFAULT_LOCALE_CODE,
  fallbackLocale: DEFAULT_LOCALE_CODE,
  messages: {
    en,
    fr,
  },
}));

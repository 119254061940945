
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "build": {
    "user": "root",
    "host": "7af591230ee1",
    "time": "2025/01/21 07:32:35",
    "commit": "db2676e70803687a8731449d863a45e5c830c21b",
    "repo": "git@bitbucket.org:rscdevelopers/vv-public.git",
    "branch": "master",
    "release": "db2676e70803687a8731449d863a45e5c830c21b"
  },
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)

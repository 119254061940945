<template lang="pug">
VBottomSheet(
  v-model="languageSheetStore.visible"
  style="max-width: 640px; margin-left: auto; margin-right: auto"
)
  VCard.the-languages-sheet
    VList
      VListItem.pt-6.pl-8.pr-8
        div.text-h3 {{ i18n.t("Settings.MainLanguage") }}
        div {{ i18n.t("Settings.MainLanguageSub") }}

      VListItem.pl-8.pr-8.pt-2.pb-6(:prepend-icon="mdiTranslate")
        SelectLanguage.mt-1
      VDivider.pt-4.pb-2
      VListItem.pl-8.pr-8.pt-2.pb-0(:prepend-icon="commentaryOutline")
        VSelect.select-reading-languages.mt-1(
          v-model="selected"
          :items="listItems"
          :label="i18n.t('Settings.ReadingLanguages')"
          :color="$vuetify.theme.name === 'dark' ? 'secondary' : 'secondary-c900'"
          variant="outlined"
          :chips="true"
          :multiple="true"
          :closable-chips="true"
          :clearable="true"
          item-title="title"
          item-value="value"
          :item-props="true"
          :hide-details="true"
        )
      VListSubheader(:inset="true")
        span.pl-4.pt-2.pb-2.d-inline-block.text-no-truncate {{ i18n.t("Settings.ReadingLanguagesSub") }}

    VCardActions.justify-end.mt-2.pr-8.pl-8.pb-8
      VBtn(
        :block="true"
        variant="outlined"
        @click="languageSheetStore.close"
      )
        VIcon.mr-2 {{ noDiff ? mdiClose : mdiCheck }}
        | {{ i18n.t(noDiff ? "Global.close" : "Global.applyAndClose") }}
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { mdiCheck, mdiClose, mdiTranslate } from "@mdi/js";
import { localesByCode } from "~/i18n/locales";
import { commentaryOutline } from "~/icons";

const i18n = useI18n();

const languageSheetStore = useLanguageSheetStore();

const preferencesStore = usePreferencesStore();
const acceptedLanguages = computed<string[]>({
  get: () => preferencesStore.acceptedLanguages ?? [],
  set(value) {
    preferencesStore.acceptedLanguages = value;
  },
});

const selected = ref<string[]>(acceptedLanguages.value);

const noDiff = computed<boolean>(() => {
  return (
    selected.value.length === acceptedLanguages.value.length &&
    selected.value.every((value) => acceptedLanguages.value.includes(value))
  );
});

watch(
  () => languageSheetStore.visible,
  (open) => {
    if (open) {
      selected.value = acceptedLanguages.value;
    } else {
      acceptedLanguages.value = selected.value;
    }
  },
);

type Items = {
  value: string;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  header?: string;
};

const listItems = computed(() => {
  const items: Items[] = Array.from(localesByCode.entries()).map(
    ([code, locale]) => {
      const _name = locale.name ?? code;
      return {
        title: _name,
        value: code,
        subtitle:
          languageSheetStore.counts === null
            ? undefined
            : i18n.t("Commentary.comments", {
                count: languageSheetStore.counts?.get(code) ?? 0,
              }),
      };
    },
  );

  return items;
});
</script>

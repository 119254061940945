<template lang="pug">
VCard
  VCardSubtitle.my-4 {{ chapter.bookName }}
  VCardTitle.text-h1(
    :class="{ 'text-primary': $vuetify.theme.name !== 'dark' }"
  ) {{ chapter.number }}
  VCardSubtitle.mb-4
    span(
      v-html="i18n.t('ScriptureIndex.comments', { count: chapterCountData.countHtml }, chapterCountData.countTotal)"
    )
  VCardActions
    VBtn(
      :title="`Read ${chapter.bookName} ${chapter.number}`"
      :to="localePath(getBibleLocationFromRange(chapter))"
      :color="$vuetify.theme.name === 'dark' ? 'primary-c050' : 'primary-c600'"
      variant="outlined"
    ) {{ i18n.t("Global.read") }}
    IndexStudyMenu(
      :commentary-types-counts="index.commentaryTypesCounts"
      :range="chapter"
      :count="index.count"
      :count-locale="index.languagesCounts[locale] ?? 0"
      :other-languages="otherLanguages"
    )
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { StandaloneChapterData } from "@rsc/scripture-model";
import type { CommentaryIndexRangeCount } from "~/units/commentary/commentaryIndex";
import { getBibleLocationFromRange } from "~/routing/locations";
import { createCountHtml, type CountData } from "./CommentaryType.util";

const i18n = useI18n();
const localePath = useLocalePath();
const locale = i18n.locale;

interface Props {
  index: CommentaryIndexRangeCount<StandaloneChapterData>;
  otherLanguages: string[];
}

const props = withDefaults(defineProps<Props>(), { otherLanguages: () => [] });

const chapter = computed(() => props.index.range);

const chapterCountData = computed<CountData>(() => {
  const countTotal: number = props.index.count;
  const countLocal: number =
    props.index.languagesCounts[i18n.locale.value] ?? 0;

  const maxCountOther = props.otherLanguages.reduce(
    (acc: null | number, currentOtherLocale: string) => {
      const count = props.index.languagesCounts[currentOtherLocale] ?? 0;
      return Math.max(count, acc ?? 0);
    },
    null,
  );

  return {
    countTotal,
    countLocal,
    maxCountOther,
    countHtml: createCountHtml({ countLocal, maxCountOther }),
  };
});
</script>

<template lang="pug">
VIcon {{ iconPath }}
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  applicationOutline,
  christocentricOutline,
  commentaryFilled,
  commentaryOutline,
  contextOutline,
  discussionOutline,
  expositionOutline,
  illustrationsOutline,
  introOutline,
  mapsOutline,
  sermonOutline,
  structureOutline,
  technicalOutline,
  themesOutline,
  timelineOutline,
  unassignedOutline,
} from "~/icons";
import type { Dict } from "~/types";

type Variant = "filled" | "outline";

const iconsByCommentaryTypeShortName: Dict<Record<Variant, string>> = {
  application: {
    filled: "",
    outline: applicationOutline,
  },
  christocentric: {
    filled: "",
    outline: christocentricOutline,
  },
  context: {
    filled: "",
    outline: contextOutline,
  },
  discussion: {
    filled: "",
    outline: discussionOutline,
  },
  exposition: {
    filled: "",
    outline: expositionOutline,
  },
  intro: {
    filled: "",
    outline: introOutline,
  },
  illustrations: {
    filled: "",
    outline: illustrationsOutline,
  },
  maps: {
    filled: "",
    outline: mapsOutline,
  },
  sermon: {
    filled: "",
    outline: sermonOutline,
  },
  structure: {
    filled: "",
    outline: structureOutline,
  },
  technical: {
    filled: "",
    outline: technicalOutline,
  },
  timeline: {
    filled: "",
    outline: timelineOutline,
  },
  themes: {
    filled: "",
    outline: themesOutline,
  },
  unassigned: {
    filled: "",
    outline: unassignedOutline,
  },
};

interface Props {
  /**
   * The short (machine-readable) name of the commentary type to which the comment belongs.
   */
  shortName: string;

  /**
   * The icon variant.
   */
  variant: Variant;
}

const props = withDefaults(defineProps<Props>(), {});

const genericFallbackIcons = {
  filled: commentaryFilled,
  outline: commentaryOutline,
};

const iconPath = computed<string>(() => {
  return (
    iconsByCommentaryTypeShortName[props.shortName]?.[props.variant] ||
    genericFallbackIcons[props.variant] ||
    ""
  );
});
</script>
